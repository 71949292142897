import moment from "moment";
import 'intl';
import 'intl/locale-data/jsonp/en'; // or any other locale you need

const setcurrency =  "GHS";
const setshortcurrency = "₵";

class Helper {

    isValidEmail = (text) => {
        var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        var valid = true;
        if (re.test(text) == false) {
            valid = false;
        }
        return valid;
    }
    
    isValidText = (text) => {   
        if(text == null || text == undefined || text ==""){
            return false;
        }
        text = text.toString();
        if (text.replace(/\s/g, "").length == 0) {
            return false;
        }
        return true;
    }

    isValidPhone = (text) => {
        if(text == null || text == undefined || text ==""){
            return false;
        }
        text = text.toString();
        if (text.replace(/\s/g, "").length == 0) {
            return false;
        }
        if (isNaN(Number(text) - 0)){
            return false;
        }
        if(text.length != 10){
            return false;
        }
        return true;
    }

    isValidNumber = (text) => {
        if(text == null || text == undefined || text ==""){
            return false;
        }
        if (text.replace(/\s/g, "").length == 0) {
            return false;
        }
        if (isNaN(Number(text) - 0)){
            return false;
        }
        return true;
    }

    isValidObject = (text) => {   
        if(text == null || text == undefined || text ==""){
            return false;
        }
        if (typeof text === 'object' && !Array.isArray(text) && text !== null) {
            return true;
        }
        else{
            return false;
        }
    }


    cgZero = (text) => {
        if(text == null || text == undefined || text ==""){
            return false;
        }
        var txtR = text.replace(",","");
        if(Number(txtR) > 0){
            return true;
        }
        else{
            return false;
        }
    }

    firstNameSentenceCase = (str) => {
        if(this.isValidText(str)==false){
            return "";
        }
        var firstletter = str.split(' ');
        firstletter = firstletter[0];
        return firstletter.split(' ')
        .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' ');
    }

    ToSentenceCase = (str) => {
        if(this.isValidText(str)==false){
            return "";
        }
        return str.replace(
            /\w\S*/g,
            function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    ToUpperCase = (str) => {
        if(this.isValidText(str)==false){
            return "";
        }
        return str.toUpperCase();
    }


    isfuturedate = (passeddate) => {
        //todays date
        var startdate = moment(moment(), "YYYY-MM-DD");
        //date passed
        var enddate = moment(passeddate,"YYYY-MM-DD");

        if(moment(enddate).isAfter(startdate)){
            return true;
        }
        else{
            return false;
        }
    }


    isAfter = (firstdate, seconddate) => {
        var firstdate = moment(firstdate,"YYYY-MM-DD");
        var seconddate = moment(seconddate,"YYYY-MM-DD");

        if(moment(firstdate).isAfter(seconddate)){
            return true;
        }
        else{
            return false;
        }
        
    }


    IsJsonString = (str) => {
        if (str == null || str == "" || str == undefined) {
            return false;
        }
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

 
    getToShortC = (amount) =>{
        if(this.isValidText(amount)==false){
            return "";
        }
        else{
            amount = amount.toString()
            let currencyFormat = Intl.NumberFormat("en-US", {
                style: "currency",
                currency: setcurrency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            let currencyval = currencyFormat.format(amount);
            currencyval = currencyval.substring(3);
            return setshortcurrency + " " + currencyval;
        }
        
    }

    getToCurrency = (amount) =>{
        if(this.isValidText(amount)==false){
            return "";
        }
        else{
            amount = amount.toString()
            let currencyFormat = Intl.NumberFormat("en-US", {
                style: "currency",
                currency: setcurrency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            return currencyFormat.format(amount);
        }
        
    }

    getUId = () =>{
        return Date.now() + (Math.random() * 100000).toFixed().toString();
    }


    
    getGreetings = () => {
        var welcome = "";  
        var date = new Date();  
        var hour = date.getHours();  
        var minute = date.getMinutes();  
        var second = date.getSeconds();  
        if (minute < 10) {  
            minute = "0" + minute;  
        }  
        if (second < 10) {  
            second = "0" + second;  
        }  
        if (hour < 12) {  
            welcome = "Good morning!";  
        } else if (hour < 17) {  
            welcome = "Good afternoon!";  
        } else {  
            welcome = "Good evening!";  
        }  
        return welcome;
    }


    getLabel = (array, value) =>{
        var result = "";
        if(array!=undefined){
            var objectIndex = array.findIndex(
                (obj) => obj.value == value
            );
            if (objectIndex != -1) {
                result = array[objectIndex].label;
            }
        }
        return result; 
    }


    returnMonthInNumber = (monthName) => {
        let monthdigit = "";
        if (monthName == "Jan") {
            monthdigit = "01";
        } else if (monthName == "Feb") {
            monthdigit = "02";
        } else if (monthName == "Mar") {
            monthdigit = "03";
        } else if (monthName == "Apr") {
            monthdigit = "04";
        } else if (monthName == "May") {
            monthdigit = "05";
        } else if (monthName == "Jun") {
            monthdigit = "06";
        } else if (monthName == "Jul") {
            monthdigit = "07";
        } else if (monthName == "Aug") {
            monthdigit = "08";
        } else if (monthName == "Sep") {
            monthdigit = "09";
        } else if (monthName == "Oct") {
            monthdigit = "10";
        } else if (monthName == "Nov") {
            monthdigit = "11";
        } else if (monthName == "Dec") {
            monthdigit = "12";
        }
        return monthdigit;
    };
    

    addAlpha = (color, opacity) => {
        const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
        return color + _opacity.toString(16).toUpperCase();
    }
    
    getCVal = (val, val2) => {
        if(this.isValidText(val)==false){
            return "";
        }
        let currencyval = this.getToCurrency(val);
        currencyval = currencyval.substring(3);
        currencyval = currencyval.split(".");
        console.log(currencyval);
        if(val2=="M"){
            return currencyval[0];
        }
        else{
            return "."+currencyval[1];
        }
    }

    elipsetxt = (string, ln) => {
        if(this.isValidText(string)==false){
            return "";
        }
        if (string.length > ln) {
            string = string.substring(0, ln) + "...";
        }
        return string;
    }


    shufflearray = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
        
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }
      
        return array;
    }


    
}
const helper = new Helper();
export default helper;