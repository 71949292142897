import React, { useEffect, useState, useRef} from "react";
import helper from "../../helpers/helpers";
import "./Video.css";
import { useSearchParams } from "react-router-dom";
import ReactLoading from 'react-loading';
import { confirm } from "react-confirm-box";
import moment from 'moment';

let localTracks = {
    audioTrack:null,
    videoTrack:null
}

let localTrackState = {
    audioTrackMuted:false,
    videoTrackMuted:false
}


let shouldStop = false;
let stopped = false;
let recordedChunks = [];
let meetingId = "";


function CustomVideoCall() {
    const joinWrapper = useRef(null);
    const footer = useRef(null);
    const micbtn = useRef(null);
    const cambtn = useRef(null);
    const leavebtn = useRef(null);

    const client = window.AgoraRTC.createClient({mode:'rtc', codec:"vp8"})
    const [config, setConfig] = useState({
        appid:"",
        token:"",
        uid:"",
        channel:"",
    });
    const [userStreams, setUserStreams] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
   
    const [loading, setLoading] = useState(null);
    const [linkvalidity, setMeetingValidity] = useState(null);
    const [errormessage, setErrorMessage] = useState("Welcome to loan hub video verification");
    const [currentuserdata, setCurrentUserData] = useState({});
    const [recordingstate, setRecordingState] = useState(false);
    const [verilink, setVeriLink] = useState("");

    
    const getuserdata = (meetingid, userid) =>{
        return new Promise((resolve, reject)=>{
            if(meetingid=="we3pull3d"){
                setErrorMessage("Verification session has ended!");
                resolve({
                    success:false,
                    message: "Verification session has ended!"
                })
            }
            else if(helper.isValidText(userid)==false || helper.isValidText(meetingid) == false){
                setMeetingValidity(false);
                setErrorMessage("Invalid Video Verification Link");
                resolve({
                    success:false,
                    message: "Invalid meeting id or userid"
                })
            }
            else{
                let data = {
                    action: "muserdata",
                    paramData: JSON.stringify({
                        meetingid:meetingid,
                        userid: userid,
                    }),
                }
    
                let requestOptions = {
                    method : 'POST',
                    headers:{
                        'Content-Type' : 'application/json'
                    },
                    body:JSON.stringify(data)
                }
    
             
                fetch("https://loanhub.flexibank.net/api/auth", requestOptions)
                .then(response => response.json())
                .then(data => {
                    if(helper.IsJsonString(data.data)==true){
                        let meetingdata = JSON.parse(data.data);
                        if(helper.isValidText(meetingdata.instname) == true){
                            setMeetingValidity(true)
                            resolve({
                                success:true,
                                message: "Success",
                                meetingdata: meetingdata
                            })
                        }
                        else{
                            setMeetingValidity(false);
                            setErrorMessage("Invalid Video Verification Link");
                            resolve({
                                success:false,
                                message: "Invalid Video Verification Link"
                            })
                        }
                    }
                    else{
                        setMeetingValidity(false);
                        setErrorMessage("Invalid Video Verification Link");
                        resolve({
                            success:false,
                            message: "Invalid Video Verification Link"
                        })
                    }
                })
                .catch((error)=>{
                    setMeetingValidity(false);
                    setErrorMessage("Unable to connect");
                    resolve({
                        success:false,
                        message: "Unable to connect",
                        data: error
                    })
                })
            }
        })
    }


    useEffect(()=>{
        const meetingid = searchParams.get("xmid");
        meetingId = searchParams.get("xmid");
        const userid = searchParams.get("xusr");
        setVeriLink(meetingid);
        
        setLoading(true);
        getuserdata(meetingid, userid)
        .then((resp)=>{
            setLoading(false);
            if(resp.success==true){
                console.log("resmet", resp.meetingdata)
                joinBtn(resp.meetingdata);
                setCurrentUserData(resp.meetingdata);
            }
        })
    }, [])


    const joinBtn = async (meetingdata) => {
        let configdata = config;
        configdata.uid = meetingdata.uid;
        configdata.channel = meetingdata.channel;
        configdata.token = meetingdata.token;
        configdata.appid = meetingdata.appid;
        setConfig(configdata);
        await joinStreams(meetingdata);
        joinWrapper.current.style.display = 'none';
        footer.current.style.display = 'flex';
    }



    const joinStreams = async (meetingdata) => {
        
        client.on("user-published", handleUserJoined);
        client.on("user-left", handleUserLeft);
        client.enableAudioVolumeIndicator();

        client.on("volume-indicator", function(evt){

            for (let i = 0; evt.length > i; i++){
                let speaker = evt[i].uid
                let volume = evt[i].level

                let players = JSON.parse(window.localStorage.getItem("players"));
                let objectIndex = players.findIndex((obj) => obj.uid == speaker);
                if(objectIndex != -1){
                    players[objectIndex].volume = volume;
                    setUserStreams(players);
                    console.log("playersvolumne", players)
                    window.localStorage.setItem("players", JSON.stringify(players));
                }

            }
        });
    
        //#6 - Set and get back tracks for local user
        [config.uid, localTracks.audioTrack, localTracks.videoTrack] = await  Promise.all([
            client.join(config.appid, config.channel, config.token ||null, config.uid ||null),
            window.AgoraRTC.createMicrophoneAudioTrack(),
            window.AgoraRTC.createCameraVideoTrack()
        ])
        

        const newplayer = {
            uid: config.uid,
            meetingdata: meetingdata
        };

        console.log("initiator", newplayer);

        setUserStreams([newplayer]);
        window.localStorage.setItem("players", JSON.stringify([newplayer]));

        localTracks.videoTrack.play(`stream-${config.uid}`);
        // setLocalTracks(localTracks);
        await client.publish([localTracks.audioTrack, localTracks.videoTrack])
    }




    const handleUserJoined = async (user, mediaType) => {
        console.log('Handle user joined1',user) 
        console.log('Handle user joined2',userStreams) 
        console.log('Handle user joined3',mediaType) 

        //#12 Subscribe ro remote users
        await client.subscribe(user, mediaType)
    
        
        if (mediaType === 'video'){
            // let players =  [...userStreams];
            let players = JSON.parse(window.localStorage.getItem("players"));
            let objectIndex = players.findIndex((obj) => obj.uid == user.uid);
            if(objectIndex != -1){
                players.splice(objectIndex, 1);
            }
            
            getuserdata(searchParams.get("xmid"), user.uid)
            .then((resp)=>{
                if(resp.success==true){
                   
                    const newplayer = {
                        uid: user.uid,
                        meetingdata: resp.meetingdata
                    };

                    console.log("new user joining", newplayer)
                    players.push(newplayer);
                    setUserStreams(players);
                    window.localStorage.setItem("players", JSON.stringify(players));
                    user.videoTrack.play(`stream-${user.uid}`)

                }
            })
        }
        
        if (mediaType === 'audio') {
            user.audioTrack.play();
        }

    }


    const handleUserLeft = (user) => {
        console.log('Handle user left!', user);
        
        let players = JSON.parse(window.localStorage.getItem("players"));
        let objectIndex = players.findIndex((obj) => obj.uid == user.uid);
        if(objectIndex != -1){
            players.splice(objectIndex, 1);
        }
        setUserStreams(players);
        window.localStorage.setItem("players", JSON.stringify(players));
    }


    const micBtn = async() => {
        if(!localTrackState.audioTrackMuted){
            await localTracks.audioTrack.setMuted(true);
            localTrackState.audioTrackMuted = true
            micbtn.current.style.backgroundColor ='rgb(255, 80, 80, 0.7)'
        }else{
            await localTracks.audioTrack.setMuted(false);
            localTrackState.audioTrackMuted = false
            micbtn.current.style.backgroundColor ='#1f1f1f8e'
    
        }
    }

    const camBtn = async()=>{
        if(!localTrackState.videoTrackMuted){
            await localTracks.videoTrack.setMuted(true);
            localTrackState.videoTrackMuted = true
            cambtn.current.style.backgroundColor ='rgb(255, 80, 80, 0.7)'
        }else{
            await localTracks.videoTrack.setMuted(false)
            localTrackState.videoTrackMuted = false
            cambtn.current.style.backgroundColor ='#1f1f1f8e'
        }
    }

    
    const leaveBtn = async()=>{

        const result = await confirm( "Do you really want to exit this session?", {
            labels: {
              confirmable: "Yes",
              cancellable: "No"
            }
        });
        if (result) {
            for (let trackName in localTracks){
                let track = localTracks[trackName]
                if(track){
                    track.stop()
                    track.close()
                    localTracks[trackName] = null
                }
            }
            
            await client.leave()
            footer.current.style.display = 'none'
            setUserStreams([]);
            joinWrapper.current.style.display = 'block';
            window.location.href = "/?xmid=we3pull3d";
            return;
        }
        else{
            console.log("You click No!");
        }
     
    }



    const recordScreen = async() => {
        const mimeType = "video/webm";
        shouldStop = false;
        const constraints = {
            video: {
                cursor: "motion",
            },
        };
        if (!(navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia)) {
            return window.alert("Screen Record not supported!");
        }
        let stream = null;
        const displayStream = await navigator.mediaDevices.getDisplayMedia({ video: { cursor: "motion" }, audio: { echoCancellation: false } });
        const audioContext = new AudioContext();

        const voiceStream = await navigator.mediaDevices.getUserMedia({ audio: { echoCancellation: false }, video: false });
        const userAudio = audioContext.createMediaStreamSource(voiceStream);

        const audioDestination = audioContext.createMediaStreamDestination();
        userAudio.connect(audioDestination);

        if (displayStream.getAudioTracks().length > 0) {
            const displayAudio = audioContext.createMediaStreamSource(displayStream);
            displayAudio.connect(audioDestination);
        }

        const tracks = [...displayStream.getVideoTracks(), ...audioDestination.stream.getTracks()];
        stream = new MediaStream(tracks);
        handleRecord({ stream, mimeType });
    }




    const handleRecord = ({ stream, mimeType }) => {
        // startRecord();
        recordedChunks = [];
        stopped = false;
        const mediaRecorder = new MediaRecorder(stream);

        mediaRecorder.ondataavailable = function (e) {
            if (e.data.size > 0) {
                recordedChunks.push(e.data);
            }

            if (shouldStop === true && stopped === false) {
                mediaRecorder.stop();
                stopped = true;
            }
        };

        mediaRecorder.onstop = function () {
            const blob = new Blob(recordedChunks, {
                type: mimeType,
            });
            
            const filename = `${moment().format("YYYY-MM-DD HH-mm-ss")} - ${currentuserdata.applicantId} - ${currentuserdata.fullname}`;
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${filename || "recording"}.webm`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        mediaRecorder.start(200);
    };



    const recordBtn = () => {
        if(recordingstate==true){
            shouldStop = true;
            setRecordingState(false);
        }
        else{
            recordScreen();
            setRecordingState(true);
        }
    }


    return (
        <main>
        
            <h2 id="site-title"  style={{borderBottomStyle:'solid', borderBottomWidth:1, borderBottomColor:'white', fontSize:14, textTransform:'uppercase'}}>Loan Supermarket Video verification  
                    {
                        recordingstate==true &&
                        <span>&nbsp;&nbsp;&nbsp;- Recording In Progress</span>
                    }
            </h2>
            

            {
                loading==false && 
                <div id="join-wrapper" ref={joinWrapper}>
                    <h3>{errormessage}</h3>
                </div>
            }
           
            
            {
                loading==true &&
                <div style={{display:'flex', flexDirection:'column', flex:1, alignItems:'center', justifyContent:'center'}}>
                    <div style={{marginTop:150}}></div>
                    <ReactLoading type={"spin"} color={"#CF7500"} height={'20%'} width={'20%'} />
                </div>
            }

            <div id="user-streams">
                {userStreams.map((each) => (
                    <div key={each.uid} className="video-containers" id={`video-wrapper-${each.uid}`}>
                      <p className="user-uid">
                          <img 
                              className="volume-icon" 
                              id={`volume-${each.uid}`}
                              src={Number(each.volume) > 0 ? "./assets/volume-on.svg" : "./assets/volume-off.svg"}  
                          />
                          <div style={{display:'flex', flexDirection:'column'}}>
                            <span style={{fontSize:14, fontWeight:'bold'}}>{`${each.meetingdata.fullname}`}</span>
                            {
                                each.meetingdata.role == "admin" &&
                                <span style={{fontSize:14}}>{helper.ToSentenceCase(each.meetingdata.instname)}</span>
                            }

                            {
                                each.meetingdata.role == "user" &&
                                <span style={{fontSize:14}}>{`\nEmployee ID: ${each.meetingdata.applicantId}`}</span>
                            }
                          </div> 
                      </p>
                    
                      <div className="video-player player" id={`stream-${each.uid}`}></div>
                  </div>
                ))}
            </div>
        
                
            <div id="footer" ref={footer}>
                <div className="icon-wrapper">
                    <img 
                        className="control-icon" 
                        id="camera-btn" 
                        src="./assets/video.svg" 
                        onClick={()=>camBtn()} 
                        ref={cambtn}
                    />
                    <p>Cam</p>
                </div>

                <div className="icon-wrapper">
                    <img className="control-icon" 
                        onClick={()=>micBtn()} 
                        id="mic-btn" 
                        src="./assets/microphone.svg" 
                        ref={micbtn}
                    />
                    <p>Mic</p>
                </div>

                {
                    currentuserdata.role == "admin" &&
                    <div className="icon-wrapper">
                        <img
                            onClick={()=>recordBtn()} 
                            className="control-icon" 
                            id="leave-btn" 
                            src="./assets/record.svg"
                        />
                        <p>Record</p>
                    </div>
                
                }
               
                <div className="icon-wrapper">
                    <img
                        onClick={()=>leaveBtn()} 
                        className="control-icon" 
                        id="leave-btn" 
                        src="./assets/exit.svg" 
                        ref={leavebtn}
                    />
                    <p>Leave</p>
                </div>
            </div>
        </main>
    )


}

export default CustomVideoCall