import React, { useEffect } from "react";
import {Routes ,Route} from 'react-router-dom';

import CustomVideoCall from "./components/VideoCall/CustomVideoCall"

const App = ()=> { 
    return (
        <Routes>
            <Route path='/' element={<CustomVideoCall/>} />
        </Routes>
    )
  
}
export default App;
